/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';

import { UseMutationOptionsType } from '@mwell-healthhub/commons';

import { sendEmailOtp, verifyEmailOtp } from '../../services/userVerificationService';

export const useVerifyEmailOtp = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(verifyEmailOtp, mutationOptions);

export const useResendEmailOtp = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(({ token }) => sendEmailOtp(token), mutationOptions);
