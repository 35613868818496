import { useEffect, useState } from 'react';

import { Banner, CreateBannerDto, UpdateBannerDto } from '@healthhub/api-lib';
import { useQueryClient } from '@tanstack/react-query';

import {
  GET_ALL_BANNERS,
  toast,
  useCreateBannerMutation,
  useDeleteBannerMutation,
  useFetchBanners,
  useUpdateBannerMutation,
} from '@mwell-healthhub/commons';

// Static banners for initial display
const BANNERS: Banner[] = [
  {
    id: 1,
    title: 'Banner 1',
    photoUrl: '',
    redirectUrl: undefined,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    order: 0,
  },
  {
    id: 2,
    title: 'Banner 2',
    photoUrl: '',
    redirectUrl: undefined,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    order: 1,
  },
  {
    id: 3,
    title: 'Banner 3',
    photoUrl: '',
    redirectUrl: undefined,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    order: 2,
  },
];

export const useBanners = () => {
  const queryClient = useQueryClient();
  const [bannerList, setBannerList] = useState<Banner[]>(BANNERS);
  const { data: banners, isLoading: isFetchBannersLoading } = useFetchBanners();

  const { mutate: createBanner } = useCreateBannerMutation({
    onSuccess: () => {
      toast({ type: 'success', message: 'Banner saved successfully' });
      queryClient.invalidateQueries([GET_ALL_BANNERS]);
    },
    onError: (error) => {
      toast({ type: 'error', message: error.message });
    },
  });

  const { mutate: updateBanner } = useUpdateBannerMutation({
    onSuccess: () => {
      toast({ type: 'success', message: 'Banner updated successfully' });
      queryClient.invalidateQueries([GET_ALL_BANNERS]);
    },
    onError: (error) => {
      toast({ type: 'error', message: error.message });
    },
  });

  const { mutate: deleteBanner } = useDeleteBannerMutation({
    onSuccess: () => {
      toast({ type: 'success', message: 'Banner removed successfully' });
      queryClient.invalidateQueries([GET_ALL_BANNERS]);
    },
    onError: (error) => {
      toast({ type: 'error', message: error.message });
    },
  });

  useEffect(() => {
    if (banners) {
      const newBannerList = bannerList.map((banner) => {
        const fetchedBanner = banners.find((b) => b.title === banner.title);
        const staticBanner = BANNERS.find((b) => b.title === banner.title);

        if (fetchedBanner) {
          return fetchedBanner as Banner;
        }

        return staticBanner as Banner;
      });
      setBannerList(newBannerList);
    }
    // @NOTE: Adding bannerList on the dependency array will cause an infinite loop
  }, [banners]);

  const handleSaveBanner = (banner: CreateBannerDto) => {
    const existingBanner = banners?.find((b) => b.title === banner.title);
    if (existingBanner) {
      const updateBannerData: UpdateBannerDto = {
        id: existingBanner.id.toString(),
        ...banner,
      };

      if (updateBannerData.redirectUrl === '') {
        delete updateBannerData.redirectUrl;
      }

      updateBanner({
        id: existingBanner.id,
        updateBannerDto: updateBannerData,
      });
    } else {
      createBanner(banner);
    }
  };

  const handleRemoveBanner = (id: number) => {
    deleteBanner(id);
  };

  return {
    bannerList,
    isFetchBannersLoading,
    handleSaveBanner,
    handleRemoveBanner,
  };
};
