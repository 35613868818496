import { useQuery } from '@tanstack/react-query';

import {
  GET_ALL_PROMOTIONS,
  GET_ALL_PUBLISHED_PROMOTIONS,
  GET_PRODUCT_BY_ID,
} from '@mwell-healthhub/commons';

import { getAllPromotions, getAllPublishedPromotions, getPromotionById } from '../../services';

export function useGetPromotionById(id: string) {
  return useQuery([GET_PRODUCT_BY_ID, id], () => getPromotionById(id), { enabled: !!id });
}

export function useGetAllPromotions() {
  return useQuery([GET_ALL_PROMOTIONS], getAllPromotions);
}

export function useGetAllPublishedPromotions() {
  return useQuery([GET_ALL_PUBLISHED_PROMOTIONS], getAllPublishedPromotions);
}
