import { CreateUserProviderDto, UpdateUserProviderDto, UserProvider } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  activateProviderUser,
  deactivateProviderUser,
  updateUserProvider,
} from '@mwell-healthhub/commons';

import { registerMerchant } from '../../services';

export const useCreateUserProviderMutation = (
  mutationOptions: MutationOptions<
    UserProvider,
    AxiosError<{ message: string }>,
    CreateUserProviderDto
  >,
) => {
  return useMutation(registerMerchant, mutationOptions);
};

export const useUpdateUserProviderMutation = (
  mutationOptions: MutationOptions<
    UserProvider,
    AxiosError<{ message: string }>,
    { id: number; user: UpdateUserProviderDto }
  >,
) => {
  return useMutation(
    (params: { id: number; user: UpdateUserProviderDto }) =>
      updateUserProvider(params.id.toString(), params.user),
    mutationOptions,
  );
};

export const useDeactivateUserProviderMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, number>,
) => {
  return useMutation(deactivateProviderUser, mutationOptions);
};

export const useActivateUserProviderMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, number>,
) => {
  return useMutation(activateProviderUser, mutationOptions);
};
