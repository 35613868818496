import { UserOperationUserAppTypeEnum, UserOperationUserTypeEnum } from '@healthhub/api-lib';
import { useQuery } from '@tanstack/react-query';

import { GET_ALL_OPERATION_USERS } from '@mwell-healthhub/commons';

import { getAllOperationUsers } from '../../services';

export function useGetAllOperationUsers(
  appType?: UserOperationUserAppTypeEnum,
  userType?: UserOperationUserTypeEnum,
) {
  return useQuery([GET_ALL_OPERATION_USERS, appType, userType], (key) => {
    const [, appType] = key.queryKey;

    return getAllOperationUsers(appType as UserOperationUserAppTypeEnum, userType);
  });
}
