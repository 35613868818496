import { AnnouncementTargetEnum } from '@healthhub/api-lib';
import * as yup from 'yup';

import { UserGender } from '@mwell-healthhub/commons';

import { AdministrativeLevel } from '../enums';
import { AnnouncementFormProperties } from '../types';

export const announcementFormSchema = yup
  .object<AnnouncementFormProperties>({
    target: yup
      .mixed<AnnouncementTargetEnum>()
      .oneOf(Object.values(AnnouncementTargetEnum))
      .required('Target is required'),
    ageRange: yup
      .object()
      .shape({
        minAge: yup
          .number()
          .min(0, 'Minimum age must be greater than or equal to 0')
          .nullable()
          .notRequired(),
        maxAge: yup
          .number()
          .min(yup.ref('minAge'), 'Maximum age must be greater than minimum age')
          .nullable()
          .notRequired(),
      })
      .nullable()
      .notRequired(),
    gender: yup.mixed<UserGender>().oneOf(Object.values(UserGender)).nullable(),
    location: yup.array().of(
      yup
        .object()
        .shape({
          name: yup.string().required('Location name is required'),
          code: yup.string().required('Location code is required'),
          id: yup.mixed().required('Location ID is required'),
          administrativeLevel: yup
            .mixed<AdministrativeLevel>()
            .oneOf(Object.values(AdministrativeLevel))
            .required('Administrative level is required'),
        })
        .when('target', (target, schema) =>
          target === (AnnouncementTargetEnum.ProvidersThroughTargeting as any)
            ? schema.required('At least one location is required')
            : schema.notRequired(),
        ),
    ),
    title: yup.string().required('Announcement title is required'),
    messageContent: yup.string().required('Message content is required'),
    redirectLink: yup.string().url('Enter a valid URL').required(),
    scheduleAnnouncement: yup.string().nullable(),
  })
  .required();

export const adminConfigSchema = yup.object().shape({
  homePageTagIds: yup.array().of(yup.string()).required(),
  transactionFee: yup
    .number()
    .required('Transaction fee is required.')
    .positive('Transaction fee must be positive.'),
  heroBanners: yup.array().of(
    yup.object().shape({
      imageUrl: yup.string().url('Must be a valid URL.').required('Photo URL is required.'),
      searchKey: yup.string().required('Search key is required'),
    }),
  ),
});
