import { useQuery } from '@tanstack/react-query';

import {
  GET_ALL_PROVIDER_USERS,
  getAllProviderUsers,
  SHOW_UNIFIED_ADMIN_ACCESS,
} from '@mwell-healthhub/commons';

export function useGetAllMerchants() {
  return useQuery([GET_ALL_PROVIDER_USERS], (key) => {
    return getAllProviderUsers(SHOW_UNIFIED_ADMIN_ACCESS);
  });
}
