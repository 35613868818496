import { Promotion, CreatePromotionDto, UpdatePromotionDto } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createPromotion, removePromotion, updatePromotion } from '../../services/promotionService';

export const useCreatePromotionMutation = (
  mutationOptions: MutationOptions<Promotion, AxiosError<{ message: string }>, CreatePromotionDto>,
) => {
  return useMutation<Promotion, AxiosError<{ message: string }>, CreatePromotionDto>(
    createPromotion,
    mutationOptions,
  );
};

export const useUpdatePromotionMutation = (
  mutationOptions: MutationOptions<
    Promotion,
    AxiosError<{ message: string }>,
    { id: number; updatePromotionDto: UpdatePromotionDto }
  >,
) => {
  return useMutation<
    Promotion,
    AxiosError<{ message: string }>,
    { id: number; updatePromotionDto: UpdatePromotionDto }
  >(
    ({ id, updatePromotionDto }) => updatePromotion(id.toString(), updatePromotionDto),
    mutationOptions,
  );
};

export const useRemovePromotionMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, { id: number }>,
) => {
  return useMutation<void, AxiosError<{ message: string }>, { id: number }>(
    ({ id }) => removePromotion(id.toString()),
    mutationOptions,
  );
};
