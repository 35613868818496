import { GetServerSideProps } from 'next';

import { SHOW_SSO } from '@mwell-healthhub/commons';

import { FirebaseLoginFormPage, SsoLoginFormPage } from '../../components/auth';

type Props = {
  isSso: boolean;
  sessionId?: string;
};

export default function LoginPage(props: Props) {
  const { isSso, sessionId } = props;

  return isSso ? <SsoLoginFormPage sessionId={sessionId} /> : <FirebaseLoginFormPage />;
}

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  try {
    const { query } = context;
    const sessionId = (query.sessionId as string) || '';

    return {
      props: {
        sessionId,
        isSso: SHOW_SSO,
      },
    };
  } catch (error) {
    console.error(error);
    return { notFound: true };
  }
};
