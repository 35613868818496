/* eslint-disable @next/next/no-img-element */
import { UserUserTypeEnum } from '@healthhub/api-lib';
import { Group, TestTube, UserScan, RefreshDouble } from 'iconoir-react';

import { GroupedRoute } from '@mwell-healthhub/commons';

export const Routes = {
  ROOT: '/',
  APPOINTMENTS: '/appointments',
  APPOINTMENT: '/appointments/:id',
  DISCOUNTS: '/discounts',
  DISBURSMENTS: '/disbursements',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  FORGOT_PASSWORD: '/auth/forgot-password',
  REGISTER: '/auth/register',
  REPORTS: '/reports',
  CONSULTATIONS: '/reports/consultations',
  LABORATORY_TESTS: '/reports/laboratory-tests',
  IMAGING_TESTS: '/reports/imaging-tests',
  REFERRALS: '/reports/referrals',
  MEDICINE_ORDERS: '/reports/medicine-orders',
  DOCTORS: '/doctors',
  DOCTOR: '/doctors/:id',
  HOSPITALS: '/hospitals',
  HOSPITAL: '/hospitals/:id',
  ORDERS: '/orders',
  ORDER: '/orders/:id',
  ORDERS_ADD: '/orders/add',
  ORDERS_EDIT: '/orders/:id/edit',
  VOUCHERS: '/vouchers',
  VOUCHER: '/vouchers/:id',
  VOUCHERS_ADD: '/vouchers/add',
  PRODUCTS: '/products',
  PRODUCT: '/products/:id',
  PRODUCT_ADD: '/products/add',
  PRODUCT_EDIT: '/products/:id/edit',
  OTP: '/auth/otp',
  PROVIDERS_DIRECTORY: '/providers/directory',
  PROVIDERS_DIRECTORY_ADD: '/providers/directory/add',
  PROVIDERS_DIRECTORY_VIEW: '/providers/directory/:id',
  PROVIDERS_DIRECTORY_SERVICES: '/providers/directory/:id/services',
  PROVIDERS_DIRECTORY_SERVICE_ADD: '/providers/directory/:id/services/add',
  PROVIDERS_DIRECTORY_SERVICE_EDIT: '/providers/directory/:id/services/:serviceId',

  PROVIDERS_DIRECTORY_ONBOARDING_PROVIDER: '/providers/directory/:id/onboarding/provider',
  PROVIDERS_DIRECTORY_ONBOARDING_BRANCH: '/providers/directory/:id/onboarding/branch',

  PROVIDERS: '/providers',
  PROVIDER: '/providers/:id/info',
  PROVIDER_APPOINTMENTS: '/providers/:id/appointments',
  PROVIDER_APPOINTMENTS_VIEW: '/providers/:id/appointments/:appointmentId',

  PROVIDER_USERS: '/providers/:id/users',
  PROVIDER_USERS_EDIT: '/providers/:id/users/:userId',
  PROVIDER_USERS_ADD: '/providers/:id/users/add',

  PROVIDER_BRANCHES: '/providers/:id/branches',
  PROVIDER_BRANCHES_EDIT: '/providers/:id/branches/:branchId',
  PROVIDER_BRANCHES_ADD: '/providers/:id/branches/add',

  PROVIDER_SERVICES: '/providers/:id/services',
  PROVIDER_SERVICES_EDIT: '/providers/:id/services/:serviceId',
  PROVIDER_SERVICES_ADD: '/providers/:id/services/add',

  PROVIDER_ADD: '/providers/add',
  PROVIDER_EDIT: '/providers/:id/edit',
  PHARMACIES: '/pharmacies',
  PHARMACY: '/pharmacies/:id',
  FINANCE_ACCOUNTS: '/finance-accounts',
  FACILITIES: '/facilities',
  FACILITY: '/facilities/:id',
  ADMIN_ACCOUNTS: '/admin-accounts',
  USERS: '/users',
  USERS_ADD: '/users/add',
  USERS_EDIT: '/users/:id/edit',
  MERCHANTS: '/merchants',
  MERCHANTS_ADD: '/merchants/add',
  MERCHANTS_EDIT: '/merchants/:id/edit',
  PROVIDER_TYPES: '/provider-types',
  PROVIDER_TYPE: '/provider-types/:id',
  PROVIDER_TYPE_EDIT: '/provider-types/:id/edit',
  PROVIDER_TYPES_ADD: '/provider-types/add',
  SERVICES: '/services',
  SERVICE: '/services/:id',
  SERVICE_ADD: '/services/add',
  SERVICE_EDIT: '/services/:id/edit',
  CATEGORIES: '/categories',
  PRODUCT_CATEGORIES: '/product-categories',
  CATEGORY: '/categories/:id',
  CATEGORY_ADD: '/categories/add',
  CATEGORY_EDIT: '/categories/:id/edit',
  BRANCH: '/branches/:id',
  BRANCHES: '/branches',
  BRANCHES_ADD: '/branches/add',
  MARKETING: '/marketing',
  MARKETING_ANNOUNCEMENT_CREATE: '/marketing/announcements/create',
  MARKETING_ANNOUNCEMENT_EDIT: '/marketing/announcements/edit/:id',
  MARKETING_ANNOUNCEMENT_VIEW: '/marketing/announcements/:id',
  MARKETING_PROMOTIONS_ADD: '/marketing/promotions/add',
  MARKETING_PROMOTIONS_EDIT: '/marketing/promotions/:id',
  TRANSACTIONS: '/transactions',
  TRANSACTION: '/transactions/:id',
  TAGS: '/tags',
  TAGS_ADD: '/tags/add',
  ADMIN_CONFIG: '/admin-config',
  FINANCE: '/finance',
  MAINTENANCE: '/maintenance',
};

export const routes: Record<string, GroupedRoute<React.ElementType>[]> = {
  [UserUserTypeEnum.Finance]: [
    {
      title: 'Main Menu',
      hasPermission: true,
      routes: [
        {
          name: 'Consultations',
          path: Routes.CONSULTATIONS,
          icon: () => (
            <img width={21} height={21} src="/static-assets/images/specialty.svg" alt="hospital" />
          ),
          hasPermission: true,
        },
        {
          name: 'Laboratory Tests',
          path: Routes.LABORATORY_TESTS,
          icon: TestTube,
          hasPermission: true,
        },
        {
          name: 'Imaging Tests',
          path: Routes.IMAGING_TESTS,
          icon: UserScan,
          hasPermission: true,
        },
        {
          name: 'Medicine Orders',
          path: Routes.MEDICINE_ORDERS,
          icon: Group,
          hasPermission: true,
        },
        {
          name: 'Referrals',
          path: Routes.REFERRALS,
          icon: RefreshDouble,
          hasPermission: true,
        },
      ],
    },
  ],
};
