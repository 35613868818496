import { CreateUserOperationDto, UpdateUserOperationDto, UserOperation } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  registerUser,
  updateUserOperation,
  deactivateOperationUser,
  activateOperationUser,
} from '../../services';

export const useCreateUserOperationMutation = (
  mutationOptions: MutationOptions<
    UserOperation,
    AxiosError<{ message: string }>,
    CreateUserOperationDto
  >,
) => {
  return useMutation(registerUser, mutationOptions);
};

export const useUpdateUserOperationMutation = (
  mutationOptions: MutationOptions<
    UserOperation,
    AxiosError<{ message: string }>,
    { id: number; user: UpdateUserOperationDto }
  >,
) => {
  return useMutation(
    (params: { id: number; user: UpdateUserOperationDto }) =>
      updateUserOperation(params.id, params.user),
    mutationOptions,
  );
};

export const useDeactivateUserOperationMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, number>,
) => {
  return useMutation(deactivateOperationUser, mutationOptions);
};

export const useActivateUserOperationMutation = (
  mutationOptions?: MutationOptions<void, AxiosError<{ message: string }>, number>,
) => {
  return useMutation(activateOperationUser, mutationOptions);
};
