/* eslint-disable @typescript-eslint/no-explicit-any */
import { SsoLoginDto, VerifySsoOtpDto } from '@healthhub/api-lib';
import { useMutation } from '@tanstack/react-query';
import { Auth, signInWithEmailAndPassword } from 'firebase/auth';
import Cookies from 'js-cookie';

import {
  OTP_TOKEN,
  SHOW_SSO,
  UseMutationOptionsType,
  checkHasOtpTokenValueExpired,
  checkIsCurrentUser,
} from '@mwell-healthhub/commons';

import {
  ssoAuthenticate,
  ssoLogin,
  ssoLogout,
  ssoResendOtpEmail,
  ssoVerifyOtp,
} from '../../services/authService';
import { sendEmailOtp } from '../../services/userVerificationService';

type LoginData = {
  email: string;
  password: string;
};

export const useLogin = (auth: Auth, mutationOptions?: UseMutationOptionsType) =>
  useMutation(async (data: LoginData) => {
    const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
    const idTokenResult = await userCredential.user.getIdTokenResult();

    await fetch('/api/login', {
      headers: {
        Authorization: `Bearer ${idTokenResult.token}`,
      },
    });

    const otpToken = Cookies.get(OTP_TOKEN) as string;
    const hasExpired = otpToken && checkHasOtpTokenValueExpired(otpToken);
    const isSameUser = otpToken && checkIsCurrentUser(otpToken, data.email);

    if (!otpToken || hasExpired || !isSameUser) {
      await sendEmailOtp();
    }
  }, mutationOptions);

export const useUserLogout = (auth: Auth, mutationOptions?: UseMutationOptionsType) =>
  useMutation(async () => {
    if (SHOW_SSO) {
      await ssoLogout();
    } else {
      await auth.signOut();
      await fetch('/api/logout');
    }

    window.location.reload();
  }, mutationOptions);

export const useSsoLogin = (mutationOptions?: UseMutationOptionsType) =>
  useMutation((data: SsoLoginDto) => ssoLogin(data), mutationOptions);

export const useSsoVerifyOtp = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(
    ({ dto, accessToken }: { dto: VerifySsoOtpDto; accessToken: string }) =>
      ssoVerifyOtp(dto, accessToken),
    mutationOptions,
  );

export const useSsoResendOtpEmail = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(
    ({ sessionId, accessToken }) => ssoResendOtpEmail({ sessionId }, accessToken),
    mutationOptions,
  );

export const useSsoAuthenticate = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(
    ({ accessToken, loginSessionToken }) => ssoAuthenticate(accessToken, loginSessionToken),
    mutationOptions,
  );

export const useSsoLogout = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(ssoLogout, mutationOptions);
